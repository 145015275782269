import { graphql } from "gatsby"
import React, { Fragment } from "react"
import { Container, Col, Row } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { v4 as uuidv4 } from "uuid"

import { PullQuote } from "../UI/PullQuote"
import Link from "../UI/Link"
import {
  quoteBlockContainerClass,
  quoteClass,
  smallImageClass,
  smallRowClass,
  boxLinkClass
} from "./LeftImageWithText.module.scss"

export const fragment = graphql`
  fragment leftImageWithTextFragment on WpPage_Flexlayouts_FlexibleLayouts_LeftImageWithText {
    item {
      title
      paragraph
      quoteText
      quoteAuthor
      link {
        title
        url
      }
      image {
        ...GatsbyImageQuery
      }
      boxTitle
      boxLink {
        title
        url
      }
      boxImage {
        ...GatsbyImageQuery
      }
    }
  }
`

const LeftImageWithText = ({ item }) => {
  return (
    <div className={`bg-pale-grey`}>
      <Container className="bg-white pt-5">
        <Row className="flex-column  flex-lg-row mx-xl-3">
          {item.map(item => {
            const img = getImage(item.image?.localFile)
            const smallImg = getImage(item?.boxImage?.localFile)
            const alt = item?.boxImage?.altText

            return (
              <Fragment key={uuidv4()}>
                <Col
                  lg={4}
                  className="d-flex justify-content-center">
                  <GatsbyImage
                    image={img}
                    alt={img?.altText}
                    className="feelingImg"
                  />
                </Col>

                <Col lg={8}>
                  <div>
                  <h3 className="mb-4">{item.title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: item.paragraph }} />
                    {item.quoteText && (
                      <div
                        className={quoteBlockContainerClass}
                        role="presentation"
                        aria-hidden="true">
                        <div className={quoteClass}>
                          <PullQuote
                            quote={item.quoteText}
                            author={item.quoteAuthor}
                          />
                        </div>
                      </div>
                    )}
                    {item.link && (
                      <Link
                        classes="linkClass"
                        link={item.link.url}
                        title={item.link.title}
                      />
                    )}

                    {smallImg && (
                      <Container>
                        <Row
                          className={`${smallRowClass} align-items-center bg-pale-grey py-4`}>
                          <Col xs={4} lg={3} className=" d-flex">
                            <GatsbyImage
                              image={smallImg}
                              alt={alt}
                              className={smallImageClass}
                            />
                          </Col>
                          <Col xs={8} lg={9} className="mx-auto">
                            <div className={`d-flex flex-column`}>
                              {item.boxTitle && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.boxTitle
                                  }}
                                  className="font-weight-bold text-start"
                                />
                              )}

                              {item.boxLink && (
                                <Link
                                  classes={`linkClass ${boxLinkClass}`}
                                  link={item.boxLink.url}
                                  title={item.boxLink.title}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    )}

                    <hr className="bg-violet my-5" style={{ height: "2px" }} />
                  </div>
                </Col>
              </Fragment>
            )
          })}
        </Row>
      </Container>
    </div>
  )
}

export default LeftImageWithText