import React from "react"
import PropTypes from "prop-types"

import QuoteIcon from "../../images/left-quote-icon.inline.svg"
import {
  textClass,
  blockquoteClass,
  quoteClass,
  iconClass,
  rightIconClass,
  authorClass
} from "./PullQuote.module.scss"

export const PullQuote = ({ quote, author }) => {
  return (
    <div className="d-flex justify-content-between">
      <QuoteIcon className={iconClass} />
      <div className={textClass}>
        <blockquote className={blockquoteClass}>
          <span
            className={quoteClass}
            dangerouslySetInnerHTML={{
              __html: quote
            }}
          />
        </blockquote>
        {author && (
          <cite
            className={`${quoteClass} ${authorClass}`}
            dangerouslySetInnerHTML={{
              __html: `- ${author}`
            }}
          />
        )}
      </div>
      <QuoteIcon className={`${iconClass} ${rightIconClass}`} />
    </div>
  )
}

PullQuote.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string
}
